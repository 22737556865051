import React from 'react'
import Layout from '../components/layout/layout'
import Footer from '../components/layout/footer'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import MediumRssFeed from '../components/rss'
import { Container, Row, Col } from 'react-bootstrap'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faMap, faShieldHeart, faBottleDroplet, faPlusCircle, faStore } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import i18next from 'i18next'

export default function Home({data}) {

  // l10n
  let storyPage

  if (i18next.language === 'cs') {
    storyPage = JSON.parse(data.page.edges[0].node.content)
  } else {
    storyPage = JSON.parse(data.page.edges[1].node.content)
  }

  return (
    <Layout>
      <GatsbySeo
        titleTemplate={storyPage.title + ' | %s'}
      />
      <div className="main-container">
        <header className="text-white">
          <div className="d-flex justify-content-center sm-adjust">
            <p style={{height: '50px', float: 'left', marginRight: '0.3em'}}>
              {storyPage.body[0].title}</p>
            <b style={{float: 'left', overflow: 'hidden', position: 'relative', height: '50px'}}>
              <div className="span1">
                {storyPage.body[0].blocks[0].title}<br />
                {storyPage.body[0].blocks[1].title}<br />
                {storyPage.body[0].blocks[2].title}
              </div>
            </b>
          </div>
        </header>
        <section>
          <div className="jumbotron about my-0 d-flex" style={{ minHeight: 'calc(100vh - 259px)', color: 'white' }} fluid={+true} >
            <Container style={{ margin: 'auto' }}>
              <Row className="px-3 px-md-6">
                <Col md={6} className="order-md-1 order-2 sm-text-center" style={{ alignSelf: 'center' }} >
                  <h1>{storyPage.body[1].title}</h1>
                  <div style={{fontSize: '20px', lineHeight: '1.3'}}>
                    <p><ReactMarkdown plugins={[gfm]} children={storyPage.body[1].content} /></p>
                  </div>
                  <p>
                    <a href="/map" className="btn sign-up" role="button" style={{fontSize: '20px'}}>{storyPage.body[1].blocks[0].title} <FontAwesomeIcon icon={faMap} /></a>
                  </p>
                </Col>
                <Col md={6} className="text-center order-md-2 order-1" style={{ margin: 'auto' }}>
                  <img src={'../../images/map_homepage.png'} className="img-fluid" style={{paddingBottom: '20px'}} alt="map_prom" />
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section>
          <div className="jumbotron my-0 d-flex" style={{ minHeight: '500px', backgroundColor: 'white' }} fluid={+true} >
            <Container style={{ margin: 'auto' }} className="text-center">
              <Row className="px-5 pb-1 justify-content-center">
                <h1>{storyPage.body[2].title}</h1>
              </Row>
              <Row className="px-5 pb-1 justify-content-center">
                <p style={{fontSize: '20px', lineHeight: '1.3'}}>{storyPage.body[2].subtitle}</p>
              </Row>
              <Row className="mt-5 px-5 justify-content-center text-center">
                <Col xs={12} sm={6} md={4}>
                  <FontAwesomeIcon icon={faShieldHeart} size='4x' color='#3771c8' />
                  <p className="pt-3"><ReactMarkdown plugins={[gfm]} children={storyPage.body[2].blocks[0].text} /></p>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FontAwesomeIcon icon={faBottleDroplet} size='4x' color='#3771c8' />
                  <p className="pt-3"><ReactMarkdown plugins={[gfm]} children={storyPage.body[2].blocks[1].text} /></p>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FontAwesomeIcon icon={faPlusCircle} size='4x' color='#3771c8' />
                  <p className="pt-3"><ReactMarkdown plugins={[gfm]} children={storyPage.body[2].blocks[2].text} /></p>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        {/*<section>
          <Jumbotron style={{ minHeight: '300px', backgroundColor: '#3771c8', color: 'white' }} className="my-0 d-flex" fluid={+true} >
            <Container style={{ margin: 'auto' }}>
              <Row className="px-3 px-md-5">
                <MediumRssFeed />
              </Row>
            </Container>
          </Jumbotron>
        </section>*/}
        <section>
          <div className="jumbotron my-0 d-flex" style={{ minHeight: '300px', backgroundColor: '#3771c8', color: 'white' }} fluid={+true} >
            <Container style={{ margin: 'auto' }}>
              <Row className="px-3 px-md-5">
                <Col md={5} className="order-md-2 order-1 text-center" style={{ margin: 'auto' }}>
                  <img src={'../../images/bot_refill.png'} className="img-fluid" style={{paddingBottom: '20px'}} alt="bot_refill" />
                </Col>
                <Col md={7} className="order-md-1 order-2 sm-text-center" style={{ alignSelf: 'center' }}>
                  <h2>{storyPage.body[3].title}</h2>
                  <p><a href="/get-a-bottle" className="btn sign-up-bottom" role="button" style={{fontSize: '20px'}}>{storyPage.body[3].blocks[0].title} <FontAwesomeIcon icon={faStore} /></a></p>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section className="quote">
          <Container className="text-white">
            <Row className="justify-content-center py-4">
              <div className="col-md-8 col-md-offset-2">
                <div style={{color: '#fff', textAlign: 'center', marginBottom: '30px'}}><FontAwesomeIcon icon={faQuoteLeft} size = '4x' /></div>
                <div>
            			<blockquote className="blockquote text-center">
            				<p><ReactMarkdown plugins={[gfm]} children={storyPage.body[4].content} /></p>
            			</blockquote>
                </div>
                <Row className="justify-content-center flex-sm-row">
                  <div className="col-6 col-sm-3" style={{textAlign:'end'}}>
                    <img src={'../../images/koen.jpg'} className="rounded-circle float-right" style={{width: '70%', height: 'auto'}} alt="koen" />
                  </div>
                  <div className="col-6 col-sm-3 my-auto">
                    <p style={{marginBottom: '2px'}}>{storyPage.body[4].blocks[0].title} <a href='https://www.linkedin.com/in/jaroslav-kobylka/'><FontAwesomeIcon icon={faLinkedin} /></a></p>
                    <p style={{marginBottom: '3px', fontSize: '13px'}}><i>{storyPage.body[4].blocks[1].title}</i></p>
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    page: allStoryblokEntry(filter: {slug: {eq: "home"}})  {
      edges {
        node {
          content
        }
      }
    }
  }
`
